import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import logo from "../assets/images/logo.png";
import $ from "jquery";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import config from "../lib/config/config";
// import { getprovider } from ".././pages/seperate/provider";
import ContractABI from "../ABI/DexbotABI.json";
import BUSDContractABI from "../ABI/BusdABI.json";
import { toast } from "react-toastify";
import QBTContractABI from "../ABI/QBTContarctABI.json";
import * as bootstrap from "bootstrap";
import {
  useEthersSigner,
  walletClientToSigner,
} from "../pages/ethersConnect.js";

import {
  // type PublicClient,
  // type WalletClient,
  useWalletClient,
  usePublicClient,
  useDisconnect,
  useConnect,
  useNetwork,
  useAccount,
  useSwitchNetwork,
} from "wagmi";
import { connection } from "../helper/connection";
import { useDispatch, useSelector } from "react-redux";
import { setWallet } from "../reducers/Actions";

let toasterOption = config.toasterOption;
export default function Navbar(props) {
  // const [currAddr, setcurrAddr] = React.useState("");
  const [blns, setBln] = React.useState(0);
  const [account, setaccount] = useState();
  const [provider, setprovider] = useState();
  const [address, setaddress] = useState("");
  const [kingPlan, setkingPlan] = useState(true);
  const [QueenPlan, setQueenPlan] = useState(false);
  const [TermsAndCon, setTermsAndCon] = useState(false);
  const [Refferal, setRefferal] = useState("");
  const [loader, setloader] = useState(false);
  const [ReffStatus, setReffStatus] = useState(false);
  const [BUSDBaln, setBUSDBaln] = useState(0);
  const [QBTBaln, setQBTBaln] = useState(0);
  const [JoinNowstatus, setJoinNowstatus] = useState(false);
  const [show, setShow] = useState(false);
  const walletConnection = useSelector((state) => state.walletConnection);

  const dispatch = useDispatch();
  const [metamaskenable, setmetamaskenable] = useState(true);

  const network = useSwitchNetwork({
    onMutate(args) {
      console.log("Mutate", args);
    },
    onError(error) {
      console.log("Error", error);
    },
  });

  useAccount({
    onConnect: ({ address, connector, isReconnected }) => {
      console.log("Connected", { address, connector, isReconnected });
    },
    onDisconnect: () => {
      console.log("Disconnected");
      var web3 = new Web3(config.rpcUrl);
      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: web3,
          address: "",
          provider: null,
          connect: "",
          isChange: "",
        })
      );
      setTimeout(function () {
        window.location.reload(false);
      }, 1500);
    },
  });

  const { disconnect } = useDisconnect();
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();

  const { chain, chains } = useNetwork();

  let chainId = config.NetworkId;
  console.log(chainId, "chainIdcccccc", chain);
  const { data: walletClient } = useWalletClient({ chainId });


  let { id } = useParams();
  console.log(walletClient, ">>>>>>>>>>>>>>>>>>>>>>>>");

  useEffect(() => {
    setConnection();
  }, [walletClient, chain?.network]);

  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });

    $(".plan_close").click(function () {
      // $('.modal-backdrop').hide();
    });
  }

  useEffect(() => {
    CheckIsUser();
    getconnect();
  }, [walletConnection, walletClient]);

  async function setConnection() {
    console.log("RRRRRRRRRRRRRRRRRRRR", walletClient);

    if (walletClient && chain && chain.id == config.NetworkId) {
      var { signer, transport } = walletClientToSigner(walletClient);
      console.log("transportsssssssssss: ", transport);
      var web3 = new Web3(transport);

      console.log("userdetails: ", {
        network: config.NetworkId,
        web3: web3,
        address: walletClient.account.address,
        provider: transport,
        connect: "yes",
        isChange: "false",
        // userdetails: userdetails
      });
      console.log(
        config.NetworkId,
        chain.id,
        "config.NetworkIdconfig.NetworkId"
      );
      //var web3New = new Web3(window.ethereum);
      //var netId = await web3New.eth.net.getId();
      //console.log(netId, 'netIdnetIdnetId')
      if (chain.id == config.NetworkId) {
        dispatch(
          setWallet({
            network: config.NetworkId,
            web3: web3,
            address: walletClient.account.address,
            provider: transport,
            connect: "yes",
            isChange: "false",
          })
        );
      }

      console.log(signer, transport, "@@@@@@@@@@@@@@@@@@@1");
    } else {
      var web3 = new Web3("https://bsc-dataseed1.binance.org");
      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: web3,
          address: "",
          provider: null,
          connect: "",
          isChange: "",
        })
      );
    }
  }

  // async function connectfunction(connector) {
  //   console.log(
  //     connector,
  //     "vvvvvvvvvvvvvvvvvvvvvvvvvvvvv",
  //     connector.connector
  //   );
  //   try {
  //     var web3 = new Web3(window.ethereum);
  //     var currnetwork = await web3.eth.net.getId();
  //     if (
  //       parseInt(currnetwork) != config.NetworkId &&
  //       connector.connector.id == "metaMask"
  //     ) {
  //       toast.error("Please connect smart chain", toasterOption);
  //       return;
  //     }
  //     var resp = connect(connector);
  //     console.log(resp, "vvvvvvvvvvvvvvvvvvvvvvvvvvvvv");
  //     window.$("#connect_wallet_modal").modal("hide");
  //     setTimeout(function () {
  //       //window.location.reload(false);
  //     }, 1000);
  //   } catch (err) {}
  // }
  async function connectfunction(connector) {

    try {
      let check = isMobile();

      if (
        check &&
        !window.ethereum && connector &&
        connector.connector.id != "walletConnect"
      ) {
        await connectMetamaskMobile();
        return;
      }
      if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        var currnetwork = await web3.eth.net.getId();
        if (
          parseInt(currnetwork) != config.NetworkId &&
          connector.connector.id == "metaMask"
        ) {
          toast.error("Please connect smart chain", toasterOption);
          return;
        }
      }


      var resp = connect(connector);
      window.$("#connect_wallet_modal").modal("hide");
      setTimeout(function () {
        //window.location.reload(false);
      }, 1000);
    } catch (err) { }
  }

  function isMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  const connectMetamaskMobile = () => {
    const dappUrl = window.location.href.split("//")[1].split("/")[0];
    // console.log('dappaaaaaaaaaaaaaaaaUrl: ', dappUrl);
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    // console.log(metamaskAppDeepLink, "dapppppp...")
    window.open(metamaskAppDeepLink, "_self");
  };

  async function CheckIsUser() {
    let address =
      walletConnection && walletConnection.address && walletConnection.network
        ? walletConnection.address
        : "";
    let provider =
      walletConnection && walletConnection.address && walletConnection.provider
        ? walletConnection.provider
        : undefined;

    console.log(address, provider, "jdhfgeradw", walletConnection);

    try {
      if (typeof provider !== "undefined") {
        var web3 = new Web3(provider);
        var DexbotContract = await new web3.eth.Contract(
          ContractABI,
          config.ContractAddress
        );
        var isRefferal = await DexbotContract.methods.userData(address).call();
        console.log(isRefferal.isUser, "isRefferal");
        setJoinNowstatus(isRefferal.isUser);
        if (isRefferal.isUser == false) {
          var getClass = $(".modal-backdrop").attr("class");
          var position = getClass ? getClass.search("fade show") : -1;
          var myModalEl = document.getElementById("selectplan_modal");
          if (
            myModalEl &&
            myModalEl != null &&
            myModalEl != undefined &&
            position == -1
          ) {
            var myModal = new bootstrap.Modal(myModalEl, { keyboard: false });
            myModal.show();
          }
        }
      }
    } catch (err) { }
  }

  document.addEventListener("DOMContentLoaded", function (event) {
    setTimeout(function () {
      console.log("**");
      var myModalEl = document.getElementById("autopool_info");
      if (myModalEl && myModalEl != null && myModalEl != undefined) {
        var myModal = new bootstrap.Modal(myModalEl, { keyboard: false });
        myModal.show();
      }
    }, 1000);
  });

  async function getconnect() {
    if (
      walletConnection &&
      walletConnection.web3 &&
      walletConnection.address &&
      walletConnection.address != undefined &&
      walletConnection.address != "" &&
      walletConnection.network != ""
    ) {
      let address = walletConnection.address;
      let provider = walletConnection.provider;

      console.log(provider, "hffhgnj");

      console.log(provider, "provider", address);
      setprovider(provider);
      setaddress(address);
      if (provider) {
        var web3 = new Web3(provider);
        try {
          if (typeof provider !== "undefined") {
            //getBNB balance
            var balance = await web3.eth.getBalance(address);
            var currbal = balance / 1e18;
            setBln(currbal.toFixed(4));

            //getBUSD balance
            var BUSDContract = await new web3.eth.Contract(
              BUSDContractABI,
              config.BUSDContractAddress
            );
            var BUSDtokenBln = await BUSDContract.methods
              .balanceOf(address)
              .call();
            setBUSDBaln((BUSDtokenBln / 1e18).toFixed(4));

            //get QBT balance
            var QBTContract = await new web3.eth.Contract(
              QBTContractABI,
              config.qbttokenAddress
            );
            var QBTtokenBln = await QBTContract.methods
              .balanceOf(address)
              .call();

            setQBTBaln((QBTtokenBln / 1e18).toFixed(4));
          } else {
            // toast.success("Please Add Metamask External", toasterOption);
          }
        } catch (err) { }
      } else {
        // toast.success("Please Add Metamask External", toasterOption);
      }
    }
  }

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  async function disconnectMetamask() {
    localStorage.clear();

    disconnect();

    setTimeout(function () {
      window.location.reload(false);
    }, 1000);
  }

  async function handelePlan(plan) {
    if (plan == "king") {
      setkingPlan(true);
      setQueenPlan(false);
    } else {
      setQueenPlan(true);
      setkingPlan(false);
    }
  }

  async function handleTermsandCondition() {
    if (TermsAndCon == true) {
      setTermsAndCon(false);
    } else {
      setTermsAndCon(true);
    }
  }

  async function handleReeferal(e) {
    var id = e.target.value;
    setRefferal(id);
  }
  async function userDefaultAdd() {
    if (ReffStatus == false) {
      setReffStatus(true);
      setRefferal("0x26f9f0c809779fea862b28a106fd883fac4a3fb7");
    } else {
      setReffStatus(false);
      setRefferal("");
    }
  }

  async function convert(n) {
    try {
      var sign = +n < 0 ? "-" : "",
        toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead, decimal, pow] = n
        .toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);
      return +pow < 0
        ? sign +
        "0." +
        "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
        lead +
        decimal
        : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
    } catch (err) {
      return 0;
    }
  }
  function validateInputAddresses(address) {
    return /^(0x){1}[0-9a-fA-F]{40}$/i.test(address);
  }
  async function SubmitPlan() {
    if (TermsAndCon != true) {
      toast.warning("Accept terms and conditions", toasterOption);
      return false;
    }
    if (Refferal == "" && id == undefined) {
      toast.error("Required referral Address", toasterOption);
      return false;
    }

    var plan = "";
    if (kingPlan) {
      plan = 1;
    } else {
      plan = 2;
    }

    console.log(id, "iddddd");
    if (id != undefined && id != "") {
      var reff = id;
    }
    if (Refferal != undefined && Refferal != "") {
      var reff = Refferal;
    }

    var web3 = new Web3(provider);
    var DexbotContract = await new web3.eth.Contract(
      ContractABI,
      config.ContractAddress
    );

    console.log(validateInputAddresses(reff), "address");
    if (validateInputAddresses(reff) == false) {
      toast.error("Invalid referral", toasterOption);
      return false;
    }
    var isRefferal = await DexbotContract.methods.userData(reff).call();

    if (isRefferal.isUser == false) {
      toast.error("Invalid referral", toasterOption);
      return false;
    }

    setloader(true);

    var BUSDContract = await new web3.eth.Contract(
      BUSDContractABI,
      config.BUSDContractAddress
    );
    var amt = await convert(100000 * 1e18);
    console.log(amt, "amt");
    await BUSDContract.methods
      .approve(config.ContractAddress, amt)
      .send({ from: address })
      .then(async (result) => {
        console.log(result, "result");
        if (result && result != "") {
          toast.success("Approved Successfully", toasterOption);
          // setloader(false);
          setloader(true);
          await DexbotContract.methods
            .buyPlan(reff, plan)
            .send({ from: address })
            .then(async (result) => {
              console.log(result, "result");
              if (result && result != "") {
                toast.success("Plan Purchased Successfully", toasterOption);
                setloader(false);
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }
            })
            .catch((error) => {
              console.log(error, "error");
              toast.error("Plan Purchased failed", toasterOption);
              setloader(false);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            });
        }
      })
      .catch((error) => {
        console.log(error, "error");
        toast.error("Approve failed", toasterOption);
        setloader(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  }

  return (
    <>
      <nav className="navbar navbar-dark main_navbar navbar-sticky">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} className="img-fluid brand_logo" alt="logo" />
          </Link>
          <div className="d-flex align-items-center">
            {console.log(provider, "provider")}
            {provider == undefined ? (
              <>
                {" "}
                <button
                  className="primary_btn me-3 mob_hide"
                  data-bs-toggle="modal"
                  data-bs-target="#connect_wallet_modal"
                >
                  Connect Wallet
                </button>
              </>
            ) : (
              <>
                {walletConnection &&
                  walletConnection.address &&
                  walletConnection.address !== "" ? (
                  <button
                    className="primary_btn me-3 mob_hide dropdown"
                    onClick={() => setShow(!show)}
                  >
                    <div class="nav-item ">
                      <Link
                        class="nav-link dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        {halfAddrShow(address)}
                      </Link>

                      <div>
                        <div
                          class={`${show ? "show" : ""
                            } dropdown-menu custom_dropdown`}
                        >
                          <Link class="dropdown-item">
                            BALANCE IN BNB : {blns}
                          </Link>
                          <Link class="dropdown-item">
                            BALANCE IN BUSD : {BUSDBaln}
                          </Link>
                          <Link class="dropdown-item">
                            BALANCE IN QBT : {QBTBaln}
                          </Link>
                          {/* <Link class="dropdown-item">
                          BALANCE IN BNB : {blns}
                        </Link> */}
                        </div>
                      </div>
                    </div>
                  </button>
                ) : walletConnection &&
                  walletConnection.address &&
                  walletConnection.address === "" ? (
                  <button
                    className="primary_btn me-3 mob_hide"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal"
                  >
                    Connect Wallet
                  </button>
                ) : (
                  <button
                    className="primary_btn me-3 mob_hide"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal"
                  >
                    Connect Wallet
                  </button>
                )}
              </>
            )}

            <button
              className="navbar-toggler border-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <img
                src={require("../assets/images/menu.png")}
                height="28"
                width="28"
                className="img-fluid"
              />
              {/* <span className="navbar-toggler-icon"></span> */}
            </button>
          </div>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                {/* After Login Menus */}
                {/* <div className="dropdown me-3">
                  <a
                    className="primary_btn dropdown-toggle address_btn"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    0x75cf28f29266a413ec878ce5c4a1c9682c819586
                  </a>
                  <ul
                    className="dropdown-menu walletdrop "
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        <label>BUSD Balance:</label>
                        <span className="ms-2">250.08 </span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Dashboarddata-bs-dismiss="offcanvas" aria-label="Close"

                      </a>
                    </li>
                  </ul>
                </div>
                <button className="primary_btn">Logout</button> */}
                {/* <button className="primary_btn">Connect Wallet</button> */}
                <ul>
                  <li data-bs-dismiss="offcanvas">
                    <Link to="/">
                      <span>01</span> HOME
                    </Link>
                  </li>
                  {console.log(sessionStorage.getItem("account"), "localSt")}
                  {walletConnection &&
                    walletConnection.address &&
                    walletConnection.address != "" ? (
                    <>
                      <li data-bs-dismiss="offcanvas">
                        <Link to="/earn">
                          <span>02</span> STAKING
                        </Link>
                      </li>
                      <li data-bs-dismiss="offcanvas">
                        <Link to="/mining">
                          <span>03</span>mining
                        </Link>
                      </li>
                      <li data-bs-dismiss="offcanvas">
                        <Link to="/dashboard">
                          <span>04</span> dashboard
                        </Link>
                      </li>
                      <li data-bs-dismiss="offcanvas">
                        <Link to="/transactionhistory">
                          <span>05</span> Transaction history
                        </Link>
                      </li>
                      <li data-bs-dismiss="offcanvas">
                        <Link to="/refferal">
                          <span>06</span> REFERRAL
                        </Link>
                      </li>
                      {/* <li data-bs-dismiss="offcanvas">
                        <Link to="/withdraw">
                          <span>07</span> withdraw
                        </Link>
                      </li>
                      <li data-bs-dismiss="offcanvas">
                        <Link to="/deposit">
                          <span>08</span> Deposit
                        </Link>
                      </li> */}
                      <li data-bs-dismiss="offcanvas">
                        <Link to="/kyc">
                          <span>09</span> Kyc
                        </Link>
                      </li>
                      <li
                        data-bs-dismiss="offcanvas"
                        onClick={() => disconnectMetamask()}
                      >
                        <Link to="#">
                          <span>07</span> LOGOUT
                        </Link>
                      </li>
                    </>
                  ) : (
                    ""
                  )}

                  <li>
                    <div className="close_shade">
                      {provider != undefined ? (
                        <>
                          {" "}
                          {walletConnection &&
                            walletConnection.address == "" ? (
                            <button
                              className="primary_btn mob_show mt-3"
                              data-bs-toggle="modal"
                              data-bs-target="#connect_wallet_modal"
                            >
                              Connect Wallet
                            </button>
                          ) : (
                            <button className="primary_btn mt-3 me-3 mob_show dropdown">
                              <div class="nav-item ">
                                <Link
                                  class="nav-link dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                >
                                  {halfAddrShow(address)}
                                </Link>

                                <div>
                                  <div class="dropdown-menu custom_dropdown">
                                    <Link class="dropdown-item">
                                      BALANCE IN BNB : {blns}
                                    </Link>
                                    <Link class="dropdown-item">
                                      BALANCE IN BUSD : {BUSDBaln}
                                    </Link>
                                    <Link class="dropdown-item">
                                      BALANCE IN QBT : {QBTBaln}
                                    </Link>
                                    {/* <Link class="dropdown-item">
                                  BALANCE IN BNB : {blns}
                                </Link> */}
                                  </div>
                                </div>
                              </div>
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          <button
                            className="primary_btn mob_show mt-3"
                            data-bs-toggle="modal"
                            data-bs-target="#connect_wallet_modal"
                          >
                            Connect Wallet
                          </button>
                        </>
                      )}
                    </div>
                  </li>
                  <li>
                    {JoinNowstatus == false && address != "" ? (
                      <>
                        <div
                          data-bs-dismiss="offcanvas"
                          className="close_shade"
                        >
                          <button
                            className="primary_btn mt-3"
                            data-bs-toggle="modal"
                            data-bs-target="#selectplan_modal"
                          >
                            Join Now{" "}
                          </button>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
        data-bs-dismiss="modal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3 row">
                  {connectors &&
                    connectors.length > 0 &&
                    connectors.map((connector) => (
                      <>
                        {connector && connector.id == "metaMask" ? (
                          <div className="col-md-6">
                            <a target="_blank" className="mb-3 d-block">
                              <button
                                type="button"
                                className="connect_wallet_button"
                                onClick={() => connectfunction({ connector })}
                              >
                                <img
                                  src={require("../assets/images/metamask.png")}
                                  className="img-fluid"
                                />{" "}
                                <span>MetaMask</span>
                              </button>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                        {connector && connector.id == "walletConnect" ? (
                          <div className="col-md-6">
                            <a target="_blank" className="mb-3 d-block">
                              <button
                                type="button"
                                className="connect_wallet_button"
                                onClick={() => connectfunction({ connector })}
                              >
                                <img
                                  src={require("../assets/images/trustwallet.png")}
                                  className="img-fluid"
                                />{" "}
                                <span>Trust Wallet</span>
                              </button>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Select Plan Modal */}
      <div
        className="modal modal-lg fade primary_modal primary_modal_chg"
        id="selectplan_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      // data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="bgimg">
              <div className="modal-header border-0 w-100 text-center">
                <div className="w-100">
                  <h2
                    className="modal-title main_title"
                    id="connect_walletModalLabel"
                  >
                    Select Plan
                  </h2>
                  <p className="mb-0">Know more about joining plans</p>
                </div>
                <button
                  type="button"
                  className="btn-close plan_close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src={require("../assets/images/close.png")}
                    alt="Queenbots"
                    className="img-fluid"
                  />
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <div className="m-2 mb-3 row">
                    <div className="col-md-6">
                      <div className="outbox">
                        <div
                          className={kingPlan ? "box selected" : "box"}
                          name="queen"
                          onClick={() => handelePlan("king")}
                        >
                          <img
                            src={require("../assets/images/king.png")}
                            alt="Queenbots"
                            className="img-fluid me-3"
                          />
                          <h4>60 BUSD</h4>
                          <img
                            src={require("../assets/images/boxshade.png")}
                            alt="Queenbots"
                            className="img-fluid shade"
                          />
                        </div>

                        {/* <h6>Features</h6>
                     <ul>
                      <li>Nulla congue eros rhoncus luctus.</li>
                      <li>Curabitur quis velit quis lectus.</li>
                      <li>Cras pharetra magna sed pulvinar.</li>
                      <li>Etiam quis est a neque hendrerit.</li>
                      <li>Sed sed libero in leo lacinia.</li>
                     </ul> */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="outbox">
                        <div
                          className={QueenPlan ? "box selected" : "box"}
                          name="queen"
                          onClick={() => handelePlan("queen")}
                        >
                          <img
                            src={require("../assets/images/queen.png")}
                            alt="Queenbots"
                            className="img-fluid"
                          />
                          <h4>200 BUSD</h4>
                          <img
                            src={require("../assets/images/r_shade.png")}
                            alt="Queenbots"
                            className="img-fluid shade"
                          />
                        </div>

                        {/* <h6>Features</h6>
                     <ul>
                      <li>Nulla congue eros rhoncus luctus.</li>
                      <li>Curabitur quis velit quis lectus.</li>
                      <li>Cras pharetra magna sed pulvinar.</li>
                      <li>Etiam quis est a neque hendrerit.</li>
                      <li>Sed sed libero in leo lacinia.</li>
                     </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="earn reff_box">
                <div className="form_flx">
                  <div className="w-100">
                    <div className="labl">
                      <span>Enter Referral ID</span>
                    </div>
                    <input
                      class="form-control primary_inp"
                      type="text"
                      value={id != undefined ? id : Refferal}
                      onChange={handleReeferal}
                    />
                  </div>
                </div>
              </div>
              <div className="bottom">
                <div class="">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      onChange={handleTermsandCondition}
                    />
                    <a
                      href="https://queenbots.com/privacy/"
                      class="form-check-label"
                      for="flexCheckDefault"
                    >
                      Terms and Conditions
                    </a>
                  </div>
                  {console.log(id, "idddfjhu")}
                  {id == undefined ? (
                    <>
                      {" "}
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultaddress"
                          onChange={userDefaultAdd}
                        />
                        <label class="form-check-label" for="defaultaddress">
                          Use default address
                        </label>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                {loader ? (
                  <>
                    <button className="primary_btn mt-3 mb-4" disabled>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  </>
                ) : (
                  <button
                    className="primary_btn mt-3 mb-4"
                    onClick={() => SubmitPlan()}
                  >
                    Join Now
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
