import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import Newsletter from "../components/Newsletter.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="page_header">
        <div className="banner_shapes">
          <div id="ct_particle_animate-a461fd73" className="shape-animate3">
            <div className="pacman">
              <div className="head"></div>
              <div className="mouth"></div>
            </div>
          </div>

          <div className="banner_circle">
            <span className="place"></span>
            <span className="border-animation ba1"></span>
            <span className="border-animation ba2"></span>
            <span className="border-animation ba3"></span>
          </div>
          <div id="ct_particle_animate-a461fd71" className="shape-animate1">
            <img
              width="91"
              height="91"
              src={require("../assets/images/banner_shape_01.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          <div id="ct_particle_animate-a461fd72" className="shape-animate2">
            <img
              width="36"
              height="38"
              src={require("../assets/images/banner_shape_02.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12" data-aos="fade-up" data-aos-duration="3000">
              <h1>Paving the way for a future of endless possibilities</h1>
              <h5>Queenbots will allow users to own and share the finest assets in <span>web3</span> history  and <span>QBT</span>,  the native token of the Queenbots ecosystem, is designed to  bridge the socio-economic gap between web3 and the real world  by integrating decentralised protocols with macroeconomics. </h5>              
              <div className="banner_btn_grps">
                <a href="#" target="_blank">
                  <img
                    src={require("../assets/images/btn_app_store.png")}
                    alt="Queenbots"
                    className="img-fluid"
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.queenbot&hl=en&gl=US" target="_blank">
                  <img
                    src={require("../assets/images/btn_play_store.png")}
                    alt="Queenbots"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 order-lg-last order-first" data-aos="fade-up" data-aos-duration="3000">
              <img
                src={require("../assets/images/banner_img.png")}
                alt="Queenbots"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section>
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-8 col-md-10 m-auto" data-aos="fade-up" data-aos-duration="3000">
                <h2 className="main_title">Que Exchange is live!</h2>               
                <p className="inner_para">
                  Que.exchange aims to revolutionize the crypto trading landscape by providing users with a seamless and secure trading experience. Built on advanced blockchain technology and supported by robust artificial intelligence algorithms, Que.exchange offers an array of features and services tailored to meet the needs of both novice and experienced traders.
                </p>
                <button className="primary_btn mt-3"><a href="https://www.que.exchange/" target="_blank">Click here to visit Que </a></button>
              </div>
            </div>
          </div>
        </section>

        <section className="wallet_section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="3000">
                <div className="text-center">
                  <img
                    src={require("../assets/images/mobile_01.png")}
                    alt="Queenbots"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="3000">
                <h2 className="main_title">
                  About Queenbots
                </h2>
                <p className="inner_para">
                  Queenbots will allow users to own and share the finest assets in web3 history and will give fastgrowing multimillion-dollar metaverse venues and platforms a new way to connect with their audience. And all this is but the very beginning! Queenbots won’t just be another Exchange or NFT marketplace. It is going to be a community first ecosystem- a movement. The Queenbots community’s passion for Web3 and DeFi is a powerful force, and we are going to help them wield it to do great things.
                </p>
                <ul className="custom_list">
                  <li>Queenbots Wallet</li>
                  <li>DeFi Services</li>
                  <li>24/7 Customer Service</li>
                  <li>DAO & Governance</li>
                  <li>Security</li>
                  <li>Token Utility</li>                  
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto" data-aos="fade-up" data-aos-duration="3000">
                <div className="cta_div">
                  <div className="cta_left_div">
                    <h2 className="main_title">
                      Staking & Mining
                    </h2>
                    <p className="inner_para">
                      Queenbots offers a wide range of DeFi services with staking and mining. Earn passive income with staking , up to 12% monthly, and with mining, 6% monthly. Short and aggressive or long term high yield farming, we have a solution that fits your needs.
                    </p>
                  </div>
                  <div className="cta_right_div">
                    <img
                      src={require("../assets/images/cta_img.png")}
                      alt="Queenbots"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="team_section">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-8 m-auto" data-aos="fade-up" data-aos-duration="3000">
                <h2 className="main_title">Our Dedicated Team</h2>
                <p className="inner_para">
                  Praesent aliquam neque mi, nec dapibus tellus porta sed.
                  Aenean interdum viverra ornare. Donec pulvinar sem nec metus
                  posuere rhoncus. Pellentesque sollicitudin feugiat semper.
                </p>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="3000">
                <div className="team_div">
                  <img
                    src={require("../assets/images/team_01.png")}
                    alt="Queenbots"
                    className="img-fluid"
                  />
                  <h2>Mark Nichols</h2>
                  <h3>Co-Founder</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="3000">
                <div className="team_div">
                  <img
                    src={require("../assets/images/team_02.png")}
                    alt="Queenbots"
                    className="img-fluid"
                  />
                  <h2>Edward Blankenship</h2>
                  <h3>Senior Software Engineer</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="3000">
                <div className="team_div">
                  <img
                    src={require("../assets/images/team_03.png")}
                    alt="Queenbots"
                    className="img-fluid"
                  />
                  <h2>Richard Norman</h2>
                  <h3>Community Manager</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="3000">
                <div className="team_div">
                  <img
                    src={require("../assets/images/team_04.png")}
                    alt="Queenbots"
                    className="img-fluid"
                  />
                  <h2>Paul Garrett</h2>
                  <h3>Advisor & Investor</h3>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="faq_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <h2 className="main_title text-center" data-aos="fade-up" data-aos-duration="3000">
                  Frequently Asked Questions
                </h2>
                <div className="faq_panel">
                  <div className="faq_row" data-aos="fade-up" data-aos-duration="3000">
                    <h2>What types of digital wallets are supported by Queenbots?</h2>
                    <img
                      src={require("../assets/images/answer.png")}
                      alt="Queenbots"
                      className="img-fluid"
                    />
                    <h3>
                      You can buy and transfer your funds through metamask and trust wallet.
                    </h3>
                  </div>

                  <div className="faq_row" data-aos="fade-up" data-aos-duration="3000">
                    <h2>Can I link more than one digital wallet to my Queenbots account?</h2>
                    <img
                      src={require("../assets/images/answer.png")}
                      alt="Queenbots"
                      className="img-fluid"
                    />
                    <h3>
                      Only one wallet can be linked per account. Any other attempts will be invalidated.
                    </h3>
                  </div>

                  <div className="faq_row" data-aos="fade-up" data-aos-duration="3000">
                    <h2>How to link my wallet to the app?</h2>
                    <img
                      src={require("../assets/images/answer.png")}
                      alt="Queenbots"
                      className="img-fluid"
                    />
                    <h3>
                      Add “QBT” from the Binance Smart Chain on your digital walllet. Copy and paste the QBT contract address in the required field. Under “Receive” you will find the wallet address that is to be linked to your Queenbots account. Copy and paste it on the Queenbots app.
                    </h3>
                  </div>

                  <div className="faq_row" data-aos="fade-up" data-aos-duration="3000">
                    <h2>Are there any fees or charges associated with the King's or Queen's subscription on the Queenbots app?</h2>
                    <img
                      src={require("../assets/images/answer.png")}
                      alt="Queenbots"
                      className="img-fluid"
                    />
                    <h3>
                      The king’s plan comes for 60 BUSD, and the queen’s plan comes for 200 BUSD. This is a one-time fee that will give you access to to all services within the Queenbots ecosystem, without any need for renewals.
                    </h3>
                  </div>

                  <div className="faq_row" data-aos="fade-up" data-aos-duration="3000">
                    <h2>
                      How long is the subscription period for either of the plans?
                    </h2>
                    <img
                      src={require("../assets/images/answer.png")}
                      alt="Queenbots"
                      className="img-fluid"
                    />
                    <h3>
                      With any subscription you take on the Queenbots app- king’s plan or queen’s plan, it’s a one time fee that gives you access to all services within the ecosystem. There is no subscription period associated with either and no renewals are required.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Newsletter /> */}
        {/* <section className="subscribe_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto" data-aos="fade-up" data-aos-duration="3000">
                <div className="subscribe_div">
                  <img
                    src={require("../assets/images/subscribe_img.png")}
                    alt="Queenbots"
                    className="img-fluid"
                  />
                  <h2 className="main_title text-center">
                    Subscribe Newsletter
                  </h2>
                  <p className="inner_para text-center">
                    Quisque sollicitudin lectus auctor, porta orci rutrum,
                    vehicula ipsum. Praesent aliquam neque mi, nec dapibus
                    tellus porta sed.{" "}
                  </p>
                  <input
                    className="form-control primary_inp"
                    placeholder="Enter Your Email"
                  />
                  <div className="text-center">
                    <button className="primary_btn">Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <Footer />
      </div>
    </div>
  );
}
