import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import DataTable from "react-data-table-component";
import config from "../lib/config/config";
import { connection } from "../helper/connection";
// import { Scrollbars } from "react-custom-scrollbars";
import Web3 from "web3";
import BUSDContractABI from "../ABI/BusdABI.json";
import ContractABI from "../ABI/DexbotABI.json";
import { MineWithDrawHistoryApi } from "../Actions/user";
import moment from "moment";
import Newsletter from "../components/Newsletter.js";
import { useDispatch, useSelector } from "react-redux";

let toasterOption = config.toasterOption;
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Transactionhistory(props) {
  const [page, setPage] = useState(0);
  const [provider1, setprovider1] = useState();
  const [address, setaddress] = useState("");
  const [allData, setAllData] = useState([]);
  const [WithdrawMineUser, setWithdrawMineUser] = useState([]);
  const [WithdrawStakeUser, setWithdrawStakeUser] = useState([]);
  const [WithdrawGainBusd, setWithdrawGainBusd] = useState([]);
  const [WithdrawReferal, setWithdrawReferal] = useState([]);
  const walletConnection = useSelector((state) => state.walletConnection);

  useEffect(() => {
    getData();
  }, [walletConnection]);

  const getData = async () => {
    var { provider, address } = await connection();
    console.log(provider, "provider12343", address);
    setprovider1(provider);
    setaddress(address);
    // if (provider1 != undefined) {
    // let params = {
    //   address: address,
    // };
    const data = await MineWithDrawHistoryApi({ address: address });
    console.log(data.result, "datasatdre5rd");
    setWithdrawMineUser(data.result?.WithdrawMineUser);
    setWithdrawStakeUser(data.result?.WithdrawStakeUser);

    setWithdrawGainBusd(data.result?.WithdrawGainBusd);
    setWithdrawReferal(data.result?.WithdrawReferal);
    // }
  };

  const Converter = (address) => {
    return `${address.slice(0, 9)}....${address.slice(address.length - 10)}`;
  };

  const columnsmineuser = [
    {
      name: "S.No",
      // selector: (row) => row.SNo,
      cell: (row, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      name: "Date",
      selector: (row) => row.Date,
      cell: (row, index) => {
        return <>{moment(row.createdAt).format("YYYY-MM-DD hh:mm")}</>;
      },
    },
    {
      name: "Amount",
      // selector: (row) => row.Plan,
      cell: (row, index) => {
        return <>{row.amount.toFixed(5)}</>;
      },
    },

    {
      name: "Hash",
      // selector: (row) => row.LevelIncome,
      cell: (row, index) => {
        return <>{Converter(row.hash)}</>;
      },
    },
    {
      name: "Referrer Address",
      // selector: (row) => row.TotalWithdrawAmount,
      cell: (row, index) => {
        return <>{Converter(row.referrerAddress)}</>;
      },
    },
  ];

  const columnsstakeuser = [
    {
      name: "S.No",
      // selector: (row) => row.SNo,
      cell: (row, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      name: "Date",
      selector: (row) => row.Date,
      cell: (row, index) => {
        return <>{moment(row.createdAt).format("YYYY-MM-DD hh:mm")}</>;
      },
    },
    {
      name: "Amount",
      // selector: (row) => row.Plan,
      cell: (row, index) => {
        return <>{row.amount.toFixed(5)}</>;
      },
    },

    {
      name: "Hash",
      // selector: (row) => row.LevelIncome,
      cell: (row, index) => {
        return <>{Converter(row.hash)}</>;
      },
    },
    {
      name: "Referrer Address",
      // selector: (row) => row.TotalWithdrawAmount,
      cell: (row, index) => {
        return <>{Converter(row.referrerAddress)}</>;
      },
    },
  ];

  const columnsGainBusd = [
    {
      name: "S.No",
      // selector: (row) => row.SNo,
      cell: (row, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      name: "Date",
      selector: (row) => row.Date,
      cell: (row, index) => {
        return <>{moment(row.createdAt).format("YYYY-MM-DD hh:mm")}</>;
      },
    },
    {
      name: "Amount",
      // selector: (row) => row.Plan,
      cell: (row, index) => {
        return <>{row.amount.toFixed(5)}</>;
      },
    },

    {
      name: "Hash",
      // selector: (row) => row.LevelIncome,
      cell: (row, index) => {
        return <>{Converter(row.hash)}</>;
      },
    },
    {
      name: "Referrer Address",
      // selector: (row) => row.TotalWithdrawAmount,
      cell: (row, index) => {
        return <>{Converter(row.referrerAddress)}</>;
      },
    },
  ];

  const columnsWithdrawReferal = [
    {
      name: "S.No",
      // selector: (row) => row.SNo,
      cell: (row, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      name: "Date",
      selector: (row) => row.Date,
      cell: (row, index) => {
        return <>{moment(row.createdAt).format("YYYY-MM-DD hh:mm")}</>;
      },
    },
    {
      name: "Amount",
      // selector: (row) => row.Plan,
      cell: (row, index) => {
        return <>{row.amount.toFixed(5)}</>;
      },
    },

    {
      name: "Hash",
      // selector: (row) => row.LevelIncome,
      cell: (row, index) => {
        return <>{Converter(row.hash)}</>;
      },
    },
    {
      name: "Referrer Address",
      // selector: (row) => row.TotalWithdrawAmount,
      cell: (row, index) => {
        return <>{Converter(row.referrerAddress)}</>;
      },
    },
  ];
 

  const columns = [
    {
      name: "S.No",
      selector: (row) => row.SNo,
    },
    {
      name: "Date",
      selector: (row) => row.Date,
    },
    {
      name: "Plan",
      selector: (row) => row.Plan,
    },
    {
      name: "Referral Income",
      selector: (row) => row.ReferralIncome,
    },
    {
      name: "Level Income",
      selector: (row) => row.LevelIncome,
    },
    {
      name: "Type",
      selector: (row) => row.Type,
    },
 
    {
      name: "Total Amount",
      selector: (row) => row.TotalAmount,
    },
    {
      name: "status",
      selector: (row) => row.status,
    },
  ];

  const data = [
    {
      id: 1,
      SNo: "1",
      Date: "13-06-2023",
      Plan: "King",
      ReferralIncome: "0 BUSD",
      LevelIncome:"0 QBT",
      Type:"Deposit", 
      TotalAmount:"3000 QBT",
      status:"Completed",
    },
    {
      id: 2,
      SNo: "2",
      Date: "13-06-2023",
      Plan: "Queen",
      ReferralIncome: "0 BUSD",
      LevelIncome:"0 QBT",
      Type:"Deposit", 
      TotalAmount:"3000 QBT",
      status:"Pending",
    },
  ];


  const columns1 = [
    {
      name: "S.No",
      selector: (row) => row.SNo,
    },
    {
      name: "Date",
      selector: (row) => row.Date,
    },
    {
      name: "Plan",
      selector: (row) => row.Plan,
    },
    {
      name: "Referral Income",
      selector: (row) => row.ReferralIncome,
    },
    {
      name: "Level Income",
      selector: (row) => row.LevelIncome,
    },
    {
      name: "Type",
      selector: (row) => row.Type,
    },
 
    {
      name: "Total Amount",
      selector: (row) => row.TotalAmount,
    },
    {
      name: "status",
      selector: (row) => row.status,
    }
  ];

  const data1 = [
    {
      id: 1,
      SNo: "1",
      Date: "13-06-2023",
      Plan: "King",
      ReferralIncome: "0 BUSD",
      LevelIncome:"0 QBT",
      Type:"Withdraw", 
      TotalAmount:"3000 QBT",
      status:<span className="green">Completed</span>,
    },
    {
      id: 2,
      SNo: "2",
      Date: "13-06-2023",
      Plan: "Queen",
      ReferralIncome: "0 BUSD",
      LevelIncome:"0 QBT",
      Type:"Withdraw", 
      TotalAmount:"3000 QBT",
      status:<span className="yellow">Pending</span>,
    },
    {
      id: 3,
      SNo: "3",
      Date: "13-06-2023",
      Plan: "Queen",
      ReferralIncome: "0 BUSD",
      LevelIncome:"0 QBT",
      Type:"Withdraw", 
      TotalAmount:"3000 QBT",
      status:<span className="red">Cancel</span>,
    },
  ];

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="refferal_ban">
        <div className="container">
          <img
            src={require("../assets/images/transaction_his.png")}
            alt="Queenbots"
            className="img-fluid mb-2"
          />
          <h2 class="main_title mb-0">TRANSACTION HISTORY</h2>
          {/* <h5 class="sub_title mb-5">
            Cras eleifend vehicula lectus id porta.
          </h5> */}
        </div>
        <div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
        </div>
      </div>
        
     <section className="history_tabs">
          <div className="container">
          {/* <Scrollbars style={{ width: "100%", height: 45 }} className="mb-3" > */}
          <ul class="nav nav-tabs border-0 mb-3" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
             <button class="nav-link active " id="one-tab" data-bs-toggle="tab" data-bs-target="#one-tab-pane" type="button" role="tab" aria-controls="one-tab-pane" aria-selected="true">
              Deposit</button>
         </li>
         <li class="nav-item" role="presentation">
             <button class="nav-link" id="two-tab" data-bs-toggle="tab" data-bs-target="#two-tab-pane" type="button" role="tab" aria-controls="two-tab-pane" aria-selected="false">Withdraw</button>
         </li>
         <li class="nav-item" role="presentation">
             <button class="nav-link" id="three-tab" data-bs-toggle="tab" data-bs-target="#three-tab-pane" type="button" role="tab" aria-controls="three-tab-pane" aria-selected="false">Mine Withdraw</button>
          </li> 
          <li class="nav-item" role="presentation">
             <button class="nav-link" id="four-tab" data-bs-toggle="tab" data-bs-target="#four-tab-pane" type="button" role="tab" aria-controls="four-tab-pane" aria-selected="false">Stake Withdraw</button>
         </li>
         <li class="nav-item" role="presentation">
             <button class="nav-link" id="five-tab" data-bs-toggle="tab" data-bs-target="#five-tab-pane" type="button" role="tab" aria-controls="five-tab-pane" aria-selected="false">Stake Mine Busd Reward</button>
          </li> 
          <li class="nav-item" role="presentation">
             <button class="nav-link" id="six-tab" data-bs-toggle="tab" data-bs-target="#six-tab-pane" type="button" role="tab" aria-controls="six-tab-pane" aria-selected="false">Stake Mine Level Reward</button>
          </li> 
          </ul>
          {/* </Scrollbars> */}
        <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="one-tab-pane" role="tabpanel" aria-labelledby="one-tab" tabindex="0">
         <div className="refferal transaction_his"> 
        <div className="box_bdr">
                <div className="flx d-flex">
                  <h5 className="sub_title  mb-0 ps-1">Deposit History</h5>
                  <select className="select_box" >
                    <option className="d-none">Filter</option>
                    <option>completed</option>
                    <option>Pending</option>
                  </select>
                </div>
                <DataTable 
                 columns={columns} 
                 data={data}
                 pagination />
              </div> 
        </div>
        </div>
        <div class="tab-pane fade" id="two-tab-pane" role="tabpanel" aria-labelledby="two-tab" tabindex="0">
        <div className="refferal transaction_his"> 
        <div className="box_bdr">
                <div className="flx d-flex">
                  <h5 className="sub_title  mb-0 ps-1">Withdraw History</h5>
                  <select className="select_box">
                    <option className="d-none">Filter</option>
                    <option>completed</option>
                    <option>Pending</option>
                  </select>
                </div>
                <DataTable 
                 columns={columns1} 
                 data={data1}
                 pagination />
              </div> 
      </div>
        </div>
        <div class="tab-pane fade" id="three-tab-pane" role="tabpanel" aria-labelledby="three-tab" tabindex="0">
        <div className="refferal transaction_his"> 
          <div className="box_bdr">
            <h5 className="sub_title  mb-0 ps-1">Mine Withdraw History</h5>
            <DataTable
              columns={columnsmineuser}
              data={WithdrawMineUser}
              pagination
            />
          </div> 
      </div>
        </div> 
        <div class="tab-pane fade" id="four-tab-pane" role="tabpanel" aria-labelledby="four-tab" tabindex="0">
        <div className="refferal transaction_his"> 
          <div className="box_bdr">
            <h5 className="sub_title  mb-0 ps-1">Stake Withdraw History</h5>
            <DataTable
              columns={columnsstakeuser}
              data={WithdrawStakeUser}
              pagination
            />
          </div> 
        </div>
     
        </div>
        <div class="tab-pane fade" id="five-tab-pane" role="tabpanel" aria-labelledby="five-tab" tabindex="0">
        <div className="refferal transaction_his"> 
          <div className="box_bdr">
            <h5 className="sub_title  mb-0 ps-1">Stake Mine Busd Reward</h5>
            <DataTable
              columns={columnsWithdrawReferal}
              data={WithdrawReferal}
              pagination
            />
          </div> 
         </div>
        </div>
        <div class="tab-pane fade" id="six-tab-pane" role="tabpanel" aria-labelledby="six-tab" tabindex="0">
        <div className="refferal transaction_his"> 
          <div className="box_bdr">
            <h5 className="sub_title  mb-0 ps-1">Stake Mine Level Reward</h5>
            <DataTable
              columns={columnsGainBusd}
              data={WithdrawGainBusd}
              pagination
            />
          </div> 
        </div>
        </div>
       </div>

          </div>
     </section>


      

      


    

   
     
      {/* <Newsletter /> */}
      {/* <section className="subscribe_section mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="subscribe_div">
                <img
                  src={require("../assets/images/subscribe_img.png")}
                  alt="Queenbots"
                  className="img-fluid"
                />
                <h2 className="main_title text-center">Subscribe Newsletter</h2>
                <p className="inner_para text-center">
                  Quisque sollicitudin lectus auctor, porta orci rutrum,
                  vehicula ipsum. Praesent aliquam neque mi, nec dapibus tellus
                  porta sed.{" "}
                </p>
                <input
                  className="form-control primary_inp"
                  placeholder="Enter Your Email"
                />
                <div className="text-center">
                  <button className="primary_btn">Subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  );
}
