import Web3 from "web3";
import ContractABI from "../ABI/DexbotABI.json";
import QBTContractABI from "../ABI/QBTContarctABI.json";
import BUSDContractABI from "../ABI/BusdABI.json";

import { connection } from "../helper/connection";

import { toast } from "react-toastify";
import config from "../lib/config/config";

let toasterOption = config.toasterOption;


async function convert(n) {
    try {
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead, decimal, pow] = n
            .toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign +
            "0." +
            "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
            lead +
            decimal
            : sign +
            lead +
            (+pow >= decimal.length
                ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
                : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
    } catch (err) {
        return 0;
    }
}


//Get BUSD balance
export async function getTokenBalanceNFC() {
    try {
        var { provider, address } = await connection();
        console.log(provider, "provider", address);


        var web3 = new Web3(provider);


        var BnbBalance = await web3.eth.getBalance(address);

        BnbBalance = BnbBalance / 10 ** 18;

        var BUSDContract = await new web3.eth.Contract(
            BUSDContractABI,
            config.BUSDContractAddress
        );

        var balanceOf = await BUSDContract.methods
            .balanceOf(address)
            .call();

        balanceOf = balanceOf && balanceOf ? parseInt(balanceOf) : 0;
        var balance = balanceOf / 10 ** 18;
        balance = await convert(balance);

        var chkDecimal = balance.toString();
        chkDecimal = chkDecimal.split(".");
        if (chkDecimal.length == 2) {
            balance = parseFloat(balance).toFixed(6);
        }

        return {
            balanceOf: balanceOf,
            balance: balance,
            BnbBalance: BnbBalance,
        };
    } catch (err) {
        return {
            balanceOf: 0,
            balance: 0,
            BnbBalance: 0,
        };
    }
}

export const sendTokenTransactionFunc = async (data) => {
    try {
        var { provider, address } = await connection();
        try {
            var web3 = new Web3(provider);

            var BUSDContract = await new web3.eth.Contract(
                BUSDContractABI,
                config.BUSDContractAddress
            );

            const amount = data.amount * 1e18;
            console.log(amount, "sdtre");

            try {
                const tx = await BUSDContract.methods.transfer(
                    data.merchant,
                    amount.toString()
                ).send({ from: address });

                console.log(tx, "txxxxxxxxx")
                const txHash = tx.transactionHash;
                // await tx.wait();
                return {
                    transactionHash: txHash,
                    status: true,
                };
            } catch (err) {
                console.log(err, "txtx");
                // toast.error(err?.toString(), toasterOption);
            }
        } catch (e) {
            console.log("err on eth.sendTokenTransactionFunc", e);
            return {
                setloader: false,
                status: false,
            };
        }
    } catch (e) {
        console.log("errrr on sendTokenTransactionFunc", e);
        return {
            setloader: false,
            status: false,
        };
    }
};

//for withdraw
export const withdrawBUSDFunc = async (params) => {
    try {
        var { provider, address } = await connection();
        try {
            var web3 = new Web3(provider);


            var DexbotContract = await new web3.eth.Contract(
                ContractABI,
                config.ContractAddress
            );

            const amount = params?.amount * 1e18;

            try {
                const tx = await DexbotContract.methods.withdrawBusd(
                    amount?.toString(),
                    params?.address,
                    params?.nonce,
                    params?.signature,
                    1
                ).send({ from: address });

                console.log(tx, "taaagfd")

                const txHash = tx.transactionHash;
                // await tx.wait();
                return {
                    transactionHash: txHash,
                    status: true,
                };
            } catch (err) {
                console.log(err, "txtx");
                toast.error(err?.toString(), toasterOption);
            }
        } catch (e) {
            console.log("err on withdrawBUSDFunc", e);
            return {
                setloader: false,
                status: false,
            };
        }
    } catch (e) {
        console.log("errrr on withdrawBUSDFunc", e);
        return {
            setloader: false,
            status: false,
        };
    }
};

export const claimContractFunc = async (params) => {
    try {
        var { provider, address } = await connection();
        try {
            var web3 = new Web3(provider);


            var DexbotContract = await new web3.eth.Contract(
                ContractABI,
                config.ContractAddress
            );

            try {
                const tx = await DexbotContract.methods.cancelWithdraw(
                    params?.amount?.toString(),
                    params?.address,
                    params?.nonce,
                    params?.signature,
                    params?.type
                ).send({ from: address })
                const txHash = tx.transactionHash;
                console.log(tx, "txtxtxhrthrttxtxtxtxtx")
                // await tx.wait();
                return {
                    transactionHash: txHash,
                    status: true,
                };
            } catch (err) {
                console.log(err, "txtx claimContractFunc");
                // toast.error(err?.toString(), toasterOption);
                return {
                    setloader: false,
                    status: false,
                };
            }
        } catch (e) {
            console.log("err on claimContractFunc", e);
            return {
                setloader: false,
                status: false,
            };
        }
    } catch (e) {
        console.log("errrr on claimContractFunc", e);
        return {
            setloader: false,
            status: false,
        };
    }
};
